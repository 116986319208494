import {BasePage, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import User from "../server/User";
import PageDefinitions from "@/providers/PageDefinitions";
import { TBasePageParameters } from '@reapptor-apps/reapptor-react-common/src/base/BasePage';
import UserContext from '@/models/server/UserContext';

export default abstract class AuthorizedPage<TProps extends TBasePageParameters = {}, TState = {}> extends BasePage<TProps, TState, UserContext> {
    public get isAcceptedRegulations(): boolean {
        const user: User | null = ch.findUser();
        return (user != null) && (user.agreementAccepted && user.registrationAccepted);
    }

    public getUser(): User {
        return ch.getUser();
    }

    public getUserId(): string {
        return ch.getUserId();
    }

    public async initializeAsync(): Promise<void> {

        if (!this.isAuthorized) {
            await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
        }

        await super.initializeAsync();

    }
}