import {BasePage, BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import { TBasePageParameters } from '@reapptor-apps/reapptor-react-common/src/base/BasePage';
import ApplicationContext from "@/models/server/ApplicationContext";

export default abstract class AnonymousPage<TParams extends BasePageParameters = {}, TState = {}>
    extends BasePage<TParams, TState, ApplicationContext> {

    public get automaticUrlChange(): boolean {
        return true;
    }

    /**
     * {@link BasePage.parameters} with strong typing.
     */
    protected get typedParameters(): TParams | null {
        return this.parameters as TParams;
    }

}