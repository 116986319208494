import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    IAccordionClassNames,
    List,
    PageContainer,
    PageHeader,
} from "@reapptor-apps/reapptor-react-components";
import {Accordion as AccordionComponent }from "@reapptor-apps/reapptor-react-components";
import {
    Accordion,
    DynamicPageAttributes,
    DynamicPageData,
    DynamicPageResponse,
    DynamicPageResponseSingleType
} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "./Company.module.scss";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import HeartIcon from "../../images/icons/ikoni_yhteiskunta.png"
import HandShakeIcon from "../../images/icons/ikoni_yhteistyo.png"
import PolicyIcon from "../../images/icons/ikoni_ajankohtaista.png"
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";

interface ICompanyProps {}

export interface ICompanyParams {}

interface ICompanyState {
    company: DynamicPageData | null;
    accordions: Accordion[]
}

export default class Company extends AnonymousPage<ICompanyProps, ICompanyState> {

    static accordionClassNames : IAccordionClassNames = {
        headerContainer: 'accordionHeaderContainer'
    }
    state: ICompanyState = {
        company: null,
        accordions: []
    };

    private readonly _listRef: React.RefObject<List<DynamicPageData>> = React.createRef();

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: DynamicPageResponseSingleType = await this.getAsync(
            "/api/Application/GetDynamicPageContent?contentType=company-info&populate=*"
        );
        if (response.data) {
            await this.setState({company: response.data});
            if(response.data.attributes.accordions && response.data.attributes.accordions.length > 0){
               await this.setState({accordions: response.data.attributes.accordions}) 
            }
        }
        window.scrollTo(0, 0);
    }
    
    private companyLinkList = [
        {
            name: Localizer.jobsPageTitle,
            destination: PageDefinitions.jobsRoute,
            icon: HandShakeIcon
        },
        {
            name: Localizer.topNavContactSupport,
            destination: PageDefinitions.contactSupportRoute,
            icon: HeartIcon
        },
        {
            name: Localizer.companyPagePrivacyPolicy,
            destination: PageDefinitions.privacyPolicyRoute,
            icon: PolicyIcon
        },
        /*{
            name: Localizer.companyPageTermsOfUse,
            destination: PageDefinitions.termsOfUseRoute,
            icon: PolicyIcon
        }*/
    ];
    

    private getIcon(item: DynamicPageData): string {
        if (item?.attributes?.icon?.data?.attributes?.url) {
            return item.attributes.icon.data.attributes?.url;
        }

        return "";
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={Localizer.companyPageTitle} />

                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        <div className="row m-0 p-0">
                            <div className={"col-md-4"} style={{paddingTop: "3rem"}}>
                                {this.companyLinkList &&
                                    this.companyLinkList.map((item) => {
                                        return (
                                            <div>
                                                <div
                                                    className={styles.listItem}
                                                    onClick={async () => await PageRouteProvider.redirectAsync(item.destination)}
                                                >
                                                    <img src={item.icon} height={40} width={40} />
                                                    <div>{item.name}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>

                            <div className={"col-md-8"} style={{paddingTop: "3rem"}}>
                                {this.state.company && (
                                    <ReactMarkdown>{this.state.company.attributes.content}</ReactMarkdown>
                                )}
                                {this.state.accordions.length > 0 && (
                                  <div className={styles.accordionContainer}>
                                      {this.state.accordions.map(accordion => {
                                          return(
                                            <AccordionComponent
                                              header={accordion.title}
                                              autoCollapse={false}
                                              className={styles.clinicAccordion}
                                              classNames={Company.accordionClassNames}
                                            >
                                                <ReactMarkdown>{accordion.content}</ReactMarkdown>
                                            </AccordionComponent>
                                          );
                                      })}
                                  </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
