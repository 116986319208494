//Autogenerated

import {BaseEnumProvider, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {WebApplicationType, PasswordValidationError, TokenType} from "@reapptor-apps/reapptor-react-common";
import {LoginResultStatus, OrganizationContractLevel, OrganizationContractType, UserRoleGroup, SavePasswordResultStatus, InvitationType, NotificationType, AuditTimestamp, AuthType} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["LoginResultStatus", "OrganizationContractLevel", "OrganizationContractType", "UserRoleGroup", "SavePasswordResultStatus", "InvitationType", "NotificationType", "AuditTimestamp", "AuthType", "SortDirection", "WebApplicationType", "PasswordValidationError", "TokenType"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractLevel

    public getOrganizationContractLevelItem(value: OrganizationContractLevel): SelectListItem {
        return this.transform(OrganizationContractLevel, "OrganizationContractLevel", value);
    }

    public getOrganizationContractLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractLevel, "OrganizationContractLevel", reverse);
    }

    public getOrganizationContractLevelName(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).text;
    }

    public getOrganizationContractLevelText(value: OrganizationContractLevel): string {
        return this.localizer.get(this.getOrganizationContractLevelName(value));
    }

    public getOrganizationContractLevelDescription(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractType

    public getOrganizationContractTypeItem(value: OrganizationContractType): SelectListItem {
        return this.transform(OrganizationContractType, "OrganizationContractType", value);
    }

    public getOrganizationContractTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractType, "OrganizationContractType", reverse);
    }

    public getOrganizationContractTypeName(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).text;
    }

    public getOrganizationContractTypeText(value: OrganizationContractType): string {
        return this.localizer.get(this.getOrganizationContractTypeName(value));
    }

    public getOrganizationContractTypeDescription(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).subtext;
    }

    // #endregion

    // #region UserRoleGroup

    public getUserRoleGroupItem(value: UserRoleGroup): SelectListItem {
        return this.transform(UserRoleGroup, "UserRoleGroup", value);
    }

    public getUserRoleGroupItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserRoleGroup, "UserRoleGroup", reverse);
    }

    public getUserRoleGroupName(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).text;
    }

    public getUserRoleGroupText(value: UserRoleGroup): string {
        return this.localizer.get(this.getUserRoleGroupName(value));
    }

    public getUserRoleGroupDescription(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region PasswordValidationError

    public getPasswordValidationErrorItem(value: PasswordValidationError): SelectListItem {
        return this.transform(PasswordValidationError, "PasswordValidationError", value);
    }

    public getPasswordValidationErrorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PasswordValidationError, "PasswordValidationError", reverse);
    }

    public getPasswordValidationErrorName(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).text;
    }

    public getPasswordValidationErrorText(value: PasswordValidationError): string {
        return this.localizer.get(this.getPasswordValidationErrorName(value));
    }

    public getPasswordValidationErrorDescription(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).subtext;
    }

    // #endregion

    // #region TokenType

    public getTokenTypeItem(value: TokenType): SelectListItem {
        return this.transform(TokenType, "TokenType", value);
    }

    public getTokenTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TokenType, "TokenType", reverse);
    }

    public getTokenTypeName(value: TokenType): string {
        return this.getTokenTypeItem(value).text;
    }

    public getTokenTypeText(value: TokenType): string {
        return this.localizer.get(this.getTokenTypeName(value));
    }

    public getTokenTypeDescription(value: TokenType): string {
        return this.getTokenTypeItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();