import {ApiProvider, ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import ApplicationContext from "@/models/server/ApplicationContext";
import {IFooterLink, ILeftNavProps} from "@reapptor-apps/reapptor-react-components";
import UserContext from "../models/server/UserContext";
import User from "@/models/server/User";
import TemplateAppConstants from "@/helpers/TemplateAppConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import {ILanguage} from "@reapptor-apps/reapptor-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import {PossibleAppointment} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import {IAppointmentBookingParams} from "@/pages/AppointmentBooking/AppointmentBooking";
import {DynamicPageData} from "@/models/cms/DynamicPageResponse";

class FenixAppController {
    private _key: string | null = null;
    private _initialized: boolean = false;
    private _initializing: boolean = false;


    private get key(): string {
        if (this._key == null) {
            this._key = `${TemplateAppConstants.applicationName}.${ch.getSessionId()}.${TemplateAppConstants.name}`;
        }
        return this._key!;
    }

    // noinspection JSIgnoredPromiseFromCall
    private async setFcmTokenAsync(fcmToken: string): Promise<void> {
        await ApiProvider.postAsync("/api/application/setFcmToken", fcmToken);

        const windowInstance = (window as any);
        windowInstance.setFcmToken = null;
    }


    public async authorizeAsync(): Promise<void> {
        if (!this._initializing) {
            this._key = null;
        }
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            try {
                // Init All Dependencies
                EnumProvider.initialize();
                TransformProvider.initialize();
                Localizer.initialize();
                PageDefinitions.initialize();

                // await this.initializePushServiceAsync();

                // parallel thread, do not await
                // noinspection ES6MissingAwait
                // this.pollOnlineDataAsync();

                this._initialized = true;
            } finally {
                this._initializing = false;
            }
        }
    }

    public get isIosNative(): boolean {
        return (typeof (window as any).webkit?.messageHandlers?.postMessageListener?.postMessage === "function");
    }

    // noinspection InfiniteRecursionJS

    public async onLogoClickAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.homeRoute, false, false);
    }

    public async changeLanguage(language: string): Promise<void> {
        await ApiProvider.postAsync("/api/application/OnSetLanguage", language);


        ch.refresh();
    }

    public getLeftNav(): ILeftNavProps | null {
        return null;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get user(): User {
        return ch.getUser();
    }

    public get fenixSupportPhone(): string {
        return "020 144 1511";
    }

    public get fenixSupportEmail(): string {
        return "asiakaspalvelu@laakarikeskusfenix.fi";
    }


    languages(): ILanguage[] {
        return [
            {
                label: "Suomi",
                code: "fi"
            },
            {
                label: "Svenska",
                code: "sv"
            }

        ];
    }

    onReserveTime(time: PossibleAppointment) {
        return Promise.resolve(undefined);
    }

    async openAppointmentsPage(selectedLocation: string | undefined = undefined) {
        const params: IAppointmentBookingParams = {} as any;
        if (selectedLocation) {
            params.selectedLocation = selectedLocation;
        }
        await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route({params}));
    }

    async openOccupationalAppointments() {
        const params: IAppointmentBookingParams = {} as any;
        params.occupationalHealth = 'true';
        await PageRouteProvider.redirectAsync(PageDefinitions.appointmentBooking.route({params}));
    }

    getIcon(item: DynamicPageData): string {
        if (item?.attributes?.icon?.data?.attributes?.url) {
            return item.attributes.icon.data.attributes?.url
        }

        return "https://www.laakarikeskusfenix.fi/strapi-images/ikoni_fenix0_bc51beeb4a.png";
    }
    
    getFenixLogo() : string {
        return "https://www.laakarikeskusfenix.fi/strapi-images/logo_b2ceaa594d.png";
    }

    defaultDoctorImage(): string {
        return "https://www.laakarikeskusfenix.fi/strapi-images/user_e2be721507.png"
    }
}

//Singleton
export default new FenixAppController();